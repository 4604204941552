import React from 'react'

import DropdownMenu from 'components/DropdownMenu/DropdownMenu'

const BroadcastManagerDraftRowEdit = ({
  data,
  showTaskCreate,
  onDeleteBroadcast,
  showTaskInfo,
  dropdownRef,
}) => {
  const editAction = [
    {
      id: 'editBroadcast',
      label: 'Edit Broadcast',
      showTaskInfo,
      onClick: () => showTaskCreate(data),
    },
    {
      id: 'deleteBroadcast',
      label: 'Delete Broadcast',
      showTaskInfo,
      color: 'red',
      divider: true,
      onClick: () => onDeleteBroadcast(data),
    },
  ]

  return (
    <DropdownMenu
      fontWeight="normal"
      actions={editAction}
      dropdownRef={dropdownRef}
    />
  )
}

export default BroadcastManagerDraftRowEdit
