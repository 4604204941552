import React from 'react'
import {
  useApi,
  Box,
  SecondaryOutlinedButton,
  PrimaryButton,
  Flex,
  Text,
  sendLog,
} from '@fivehealth/botero'
import { useHistory } from 'react-router-dom'
import { useModal } from 'context/ModalContext'

import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import EventsConstant from '../../../../config/constants/events.constants'

const UserGroupsTableRowEdit = ({
  data,
  showEditView,
  showGroupEditView,
  dropdownRef,
  setIsDeleting,
  isArchivedView = false,
}) => {
  const history = useHistory()
  const {
    queries: { useProfilesGroupDelete },
  } = useApi({
    queries: ['useProfilesGroupDelete'],
  })
  const { openModal, closeModal } = useModal()

  const { mutateAsync: deleteProfilesGroup } = useProfilesGroupDelete({
    variables: {},
    onSuccess: ({ queryClient, data: resultData }) => {
      setIsDeleting(false)
      if (resultData?.errors?.length) {
        sendLog(
          {
            subSource: EventsConstant.USER_GROUP_TABLE_SOURCE,
            page: EventsConstant.USER_GROUP_PAGE,
            metaData: JSON.stringify(resultData.errors || []),
          },
          EventsConstant.USER_GROUP_ERROR_DELETE
        )
      }
      if (queryClient) {
        queryClient.invalidateQueries('profilesGroup')
      }
    },
  })

  const sendBroadcast = () => {
    history.push('/broadcast_create', {
      defaultData: {
        recipients: [
          {
            uid: data.uid,
            label: data.name,
            type: 'profilesGroup',
            value: data,
            addOptions: 'false',
          },
        ],
      },
      isIndividualMode: true,
      defaultValue: [data.uid],
    })
  }

  const activeActions = [
    {
      id: 'viewGroup',
      label: 'Edit Group Details',
      onClick: () => {
        showGroupEditView({ group: data, archivedView: isArchivedView })
      },
      logEventProps: {
        subSource: EventsConstant.USER_GROUP_TABLE_SOURCE,
        eventName: EventsConstant.CREATE_USER_GROUP_OPENED,
        page: EventsConstant.USER_GROUP_PAGE,
      },
    },
    {
      id: 'viewGroupMembers',
      label: 'Edit Group Members',
      onClick: () => showEditView({ ...data, archivedView: isArchivedView }),
      logEventProps: {
        subSource: EventsConstant.USER_GROUP_TABLE_SOURCE,
        eventName: EventsConstant.USER_GROUP_EDIT_GROUP_MEMBERS,
        page: EventsConstant.USER_GROUP_PAGE,
      },
    },
    {
      id: 'sendBroadcast',
      label: 'Send Broadcast',
      onClick: sendBroadcast,
      logEventProps: {
        subSource: EventsConstant.USER_GROUP_TABLE_SOURCE,
        eventName: EventsConstant.USER_GROUP_SEND_BROADCAST,
        page: EventsConstant.USER_GROUP_PAGE,
      },
    },
    {
      id: 'archiveGroup',
      label: 'Delete Group',
      color: 'red',
      divider: true,
      onClick: () => {
        openModal(
          <Box width="480px" p={4}>
            <Text fontWeight="bold" fontSize={3} mb={6}>
              Are you sure?
            </Text>
            <Text fontSize={2} mb={6}>
              Are you sure you want to delete the selected group?
            </Text>
            <Flex justifyContent="right" alignItems="center">
              <SecondaryOutlinedButton
                borderRadius="8px"
                mr={2}
                onClick={() => {
                  closeModal()
                }}
              >
                Cancel
              </SecondaryOutlinedButton>
              <PrimaryButton
                borderRadius="8px"
                style={{ backgroundColor: '#E05138' }}
                onClick={() => {
                  closeModal()
                  setIsDeleting(true)
                  deleteProfilesGroup({
                    input: {
                      uid: data.uid,
                    },
                  })
                }}
              >
                Delete
              </PrimaryButton>
            </Flex>
          </Box>
        )
      },
      logEventProps: {
        subSource: EventsConstant.USER_GROUP_TABLE_SOURCE,
        eventName: EventsConstant.USER_GROUP_DELETE_GROUP_PROMPTED,
        page: EventsConstant.USER_GROUP_PAGE,
      },
    },
  ]

  const archivedActions = [
    {
      id: 'viewGroup',
      label: 'Edit Group Members',
      onClick: () => showEditView({ ...data, archivedView: isArchivedView }),
      logEventProps: {
        subSource: EventsConstant.USER_GROUP_TABLE_SOURCE,
        eventName: EventsConstant.USER_GROUP_EDIT_DETAILS,
        page: EventsConstant.USER_GROUP_PAGE,
      },
    },
    {
      id: 'restoreGroup',
      label: 'Restore Group',
      onClick: () =>
        deleteProfilesGroup({
          input: {
            uid: data.uid,
          },
        }),
      logEventProps: {
        subSource: EventsConstant.USER_GROUP_TABLE_SOURCE,
        eventName: EventsConstant.USER_GROUP_RESTORE_GROUP_PROMPTED,
        page: EventsConstant.USER_GROUP_PAGE,
      },
    },
  ]

  return (
    <DropdownMenu
      fontWeight="normal"
      actions={isArchivedView ? archivedActions : activeActions}
      dropdownRef={dropdownRef}
    />
  )
}

export default UserGroupsTableRowEdit
