import React, { useRef } from 'react'
import _ from 'lodash'

import {
  ActionMenu,
  ActionMenuItem,
  Flex,
  Text,
  Box,
  FAIcon,
} from '@fivehealth/botero'

const DropdownMenu = ({
  actions,
  label,
  dropdownRef,
  moveLeft,
  disabled,
  fontWeight = '500',
  testId,
  ...props
}) => {
  const currntDropdownRef = dropdownRef || useRef()

  const onSetDropDownRef = (ref) => {
    currntDropdownRef.current = ref
  }

  const handleOnClick = (event, action) => {
    currntDropdownRef.current.setOpen(false)
    const onClick = _.get(action, 'onClick', null)

    if (!_.isNull(onClick)) {
      return onClick(event)
    }

    return null
  }
  return (
    <div data-testid={testId}>
      <ActionMenu
        pb="8px"
        ml={moveLeft}
        disabled={disabled}
        dropdownRef={onSetDropDownRef}
        label={label}
      >
        {_.map(actions, (action) => {
          let color = null
          if (_.get(action, 'enable', true)) {
            color = _.get(action, 'color', 'fullShade')
          } else {
            color = 'darkShade'
          }

          if (action.visibility === false) {
            return null // Skip rendering this ActionMenuItem if visibility is false
          }
          return (
            <ActionMenuItem
              key={action.id}
              display={action.visibility ? 'box' : 'none'}
              divider={_.get(action, 'divider', false)}
              onClick={(event) =>
                _.get(action, 'enable', true)
                  ? handleOnClick(event, action)
                  : null
              }
              {...props}
              data-testid={action.testId}
              logEventProps={action.logEventProps}
            >
              <Flex mb="4px" ml="4px" pt="8px">
                {action.icon && (
                  <Box>
                    <Box width="16px" height="16px">
                      <FAIcon ml="8px" color="darkShade" icon={action.icon} />
                    </Box>
                  </Box>
                )}
                <Box ml={action.icon ? '22px' : '0px'} alignItems="left">
                  <Text
                    fontFamily="Inter, sans-serif"
                    textAlign="left"
                    mt="-4px"
                    fontSize="14px"
                    lineHeight="24px"
                    fontStyle="normal"
                    letterSpacing="-0.08px"
                    fontWeight={fontWeight}
                    color={color}
                  >
                    {action.label}
                  </Text>
                  {action.description && (
                    <Text
                      width="222px"
                      fontFamily="Inter, sans-serif"
                      textAlign="left"
                      fontSize="15px"
                      fontWeight="400"
                      letterSpacing="-0.08px"
                      lineHeight="24px"
                      fontStyle="normal"
                      color="darkestShade"
                    >
                      {action.description}
                    </Text>
                  )}
                </Box>
              </Flex>
            </ActionMenuItem>
          )
        })}
      </ActionMenu>
    </div>
  )
}

export default DropdownMenu
